//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

.hidden{ display:none !important;}

#kt_help{ z-index: 5000 }


blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote p {
  display: inline;
}

.audio-upload-button {
  margin-left: -10px;
  margin-top: -80px;
}

.audio-delete-button {
  margin-left: -25px;
  margin-top: 15px;
}

.file-upload-button {
  margin-top: -12px;
  margin-left: 18px;
}

.file-delete-button {
  margin-top: -12px;
  margin-left: 8px;
}


table { caption-side: top; }
caption { font-size: 22px; font-weight: bold; }
